// General :: Remove autofill (Chrome)

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}
// Input :: Text

.form-control {
	color: $bk-a !important;
	border-color: $bk-a;
	box-shadow: none;
	border-radius: 0;
	-webkit-appearance: none;
	padding-top: 7px !important;
	-moz-appearance: none;
	font-size: 12px !important;
	&.form-resa {
		border-color: #CFD4D9 !important;
	}

	&:focus {
		border-color: $bk-a;
		box-shadow: none;
		color: $bk-a;
		background: $wh-a;
	}
}
// Input :: Text Placeholder

.form-control.placeholder::placeholder {
	color: $bk-a;
}
// Input :: Checkbox & Radio

.checkboxradio-wp {
	position: relative;
	display: block;

	> label,
	> input {
		cursor: pointer;
	}

	> input {
		position: absolute;
		-moz-opacity: 0;
		opacity: 0;
		filter: alpha(opacity = 0);
	}

	> input[type=checkbox] + label,
	> input[type=radio] + label {
		display: block;
		padding: 0 0 0 30px;
		font-size: rem(13);
		font-weight: 300;
		color: $bk-a;
		background-image: url("../images/icons/sprite-checkbox_radio.png");
		line-height: 1.3em;
		background-repeat: no-repeat;
		background-size: 17px;
	}

	> input[type=radio] + label {
		background-position: 0 -168px;
	}

	> input[type=checkbox] + label {
		background-position: 0 -537px;
	}

	> input[type=radio]:checked + label {
		background-position: 0 0;
	}

	> input[type=checkbox]:checked + label {
		background-position: 0 -352px;
		color: $bl-a;
		font-weight: 500;
	}
}
// Input :: Radio ON/OFF

.btn-switch-wp {
	position: relative;
	display: flex;
	align-items: center;

	.label {
		padding-left: 15px;
		color: $bk-a;
		font-weight: 400;
		cursor: pointer;
	}

	.checkbox-toggle {
		position: absolute;
		margin-left: -9999px;
		visibility: hidden;
	}

	.checkbox-toggle + label {
		display: block;
		position: relative;
		cursor: pointer;
		outline: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.checkbox-toggle-round + label {
		margin: 0;
		padding: 2px;
		width: 55px;
		height: 31px;
		background-color: $bk-a;
		border-radius: 60px;
		transition: background 0.2s;
	}

	.checkbox-toggle-round + label:after,
	.checkbox-toggle-round + label:before {
		display: block;
		position: absolute;
		content: "";
	}

	.checkbox-toggle-round + label:after {
		top: 4px;
		left: 4px;
		bottom: null;
		width: 23px;
		height: 23px;
		background-color: $wh-a;
		border-radius: 52px;
		transition: margin 0.2s, background 0.2s;
	}

	.checkbox-toggle-round:checked + label {
		background-color: tint($bk-a,50%);
	}

	.checkbox-toggle-round:checked + label:after {
		margin-left: 23px;
	}
}
// Input :: Range

.range-wp {
	position: relative;
	padding: 0 px(10) px(30);

	.value-wp {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: absolute;
		top: px(30);
		left: 0;
	}

	.value {
		color: $bk-a;
	}

	.value.max {
		text-align: right;
	}

	.slider.slider-horizontal {
		width: 100%;
	}

	.slider-handle {
		background: $bk-a;
	}

	.slider-track {
		width: calc(100% + 20px) !important;
		left: -10px !important;
		background: tint($bk-a,75%) !important;
	}

	.slider-selection {
		background: tint($bk-a, 90%) !important;
	}
}
// Input :: Datepicker

.datepicker-wp {
	position: relative;

	.form-control {
		padding-right: px(30);
	}

	.ui-datepicker-trigger {
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: px(-8);

		.material-icons {
			font-size: px(18);
		}
	}
}

.ui-datepicker {
	font-size: px(12);
	background-image: none !important;
	border-radius: 0 !important;

	.ui-widget-header {
		background: tint($bk-a, 80%) !important;
		border-radius: 0 !important;
		border: none !important;
	}

	.ui-button,
	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default {
		border: none;
		background: tint($bk-a,95%);
	}

	.ui-datepicker-today .ui-state-highlight {
		border: none;
		background: tint($bk-a,85%);
	}

	.ui-button.ui-state-active:hover,
	.ui-button:active,
	.ui-state-active,
	.ui-widget-content .ui-state-active,
	.ui-widget-header .ui-state-active,
	a.ui-button:active {
		border: none;
		background: $or-a;
	}

	.ui-button:focus,
	.ui-button:hover,
	.ui-state-focus,
	.ui-state-hover,
	.ui-widget-content .ui-state-focus,
	.ui-widget-content .ui-state-hover,
	.ui-widget-header .ui-state-focus,
	.ui-widget-header .ui-state-hover {
		background: $or-a;
		color: $wh-a;
	}
}
// Input :: File

.custom-file-wp {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;

	.custom-file-input {
		position: relative;
		z-index: 2;
		width: 100%;
		height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0;
	}

	.custom-file-label {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1;
		height: calc(2.25rem + 2px);
		@extend .form-control;
	}

	.custom-file-label::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(calc(2.25rem + 2px) - 1px * 2);
		padding: 0 px(15);
		background-color: $bk-a;
		color: $wh-a;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.custom-file-input:lang(fr)~.custom-file-label::after {
		content: "Rechercher";
	}
}
// Input :: Select Structure

.selectpicker-wp {
	position: relative;

	.bootstrap-select {
		width: 100% !important;
	}

	.filter-option {
		@include ellipsis;
	}

	.dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
	}

	.dropdown-toggle {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: px(30) !important;
		@extend .form-control;
		outline: none;
	}

	.dropdown-toggle:focus {
		outline: none !important;
	}

	.dropdown-toggle:after {
		@include position(absolute, 50% 10px null null);
		margin-top: -1px;
		color: $bk-a;
		font-size: 15px;
	}

	.bootstrap-select.show .dropdown-toggle:after {
		transform: rotate(-180deg);
	}

	&.form-resa {
		.dropdown-toggle .filter-option-inner-inner {
			color: #919191 !important;
			font-size: 13px !important;

		}
		.dropdown.changed .dropdown-toggle .filter-option-inner-inner {
			color: $bk-a !important;
		}

		.form-control {
			border-color: #CFD4D9;
			font-size: 13px !important;

		}
	}
}
// Input :: Select Dropdown

.bootstrap-select.btn-group .dropdown-menu.inner {
	display: block;
}

.bootstrap-select.btn-group .dropdown-menu {
	padding: 0;

	li a,
	li a span.text {
		display: block;
	}

	li a {
		display: block;
		padding: px(5) px(15);
	}

	li a {
		outline: none !important;
	}

	li:not(:last-child) {
		border-bottom: 1px solid #f8f9fa;
	}

	li a:focus,
	li a:hover {
		background-color: #f8f9fa;
	}
}

.bootstrap-select .dropdown-menu .inner {
	max-height: 200px !important;
}

.bootstrap-select .dropdown-menu {
	min-width: 100% !important;
	max-width: 100% !important;

}
// Validation :: Error

.parsley-errors-list {
	color: #a94442;
	font-size: px(11);
	margin-top: px(5);
}

.custom-file-input.parsley-error + .custom-file-label,
.form-control.parsley-error {
	border-color: #a94442;
	color: #a94442 !important;
}

.form-control.parsley-error::placeholder {
	color: #a94442;
}

.dropdown-menu {
	min-height: 0 !important;
	.inner {
		min-height: 0 !important;
	}
}

.dropdown-item {
	white-space: normal !important;
	font-size: 12px;
	padding: 0.25rem .75rem;
}

.dropdown-item.active, .dropdown-item:active {
	background-color: $or-a;
}


.input-wp {
	.ui-datepicker-trigger {
		display: none;
	}
}
