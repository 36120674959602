.resa-car-wp {
  .resa-car.except-parc .column.price-wp .bloc {
    height: 100%;
  }

  .resa-car.except-parc .column.price-wp .bloc .btn-submit {
    padding-top: 17px;
  }

  .resa-car.except-parc .column.price-wp .total:before {
    top: 3px;
  }

  .resa-car.except-parc .column.price-wp .price-total small {
    font-weight: 400;
    font-size: 11px;
  }

  .promo {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 60px;
    height: 60px;
    background-color: $bl-a;
    border-radius: 60px;
    text-transform: uppercase;
    z-index: 4;
    font-size: px(10);
    color: $wh-a;
    text-align: center;
    font-weight: 600;
    display: block;
    padding-top: 24px;
    line-height: 1em;
    transform: rotate(23deg) scale(1.2);
  }

  .resa-car.unavailable:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: rgba(255,255,255,0.5);
    width: calc(100% - 350px);
    height: 100%;
  }

  .single-col {
    height: 100%;
    padding: 30px 50px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .btn-submit {
      font-size: 14px !important;
    }
    @include media-breakpoint-down(xs) {
      padding: 20px;
    }
  }

  .resa-car .unavailable-date,
  .resa-car .unavailable-txt {
    color: $bl-a;
    line-height: 1.2em;
    text-align: center;
    font-size: px(13);
  }

  .resa-car .unavailable-date {
    font-family: 'museo500';
    margin-top: px(10);
    font-size: px(14);
  }

  .item {
    margin-bottom: 50px;
  }

  .resa-car {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid shade($gy-a,5%);
    border-radius: 10px;
    background-color: $wh-a;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
  }

  .resa-car:focus,
  .resa-car:hover {
    box-shadow: box-shadow, 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  }

  .resa-car .column {
    padding: 30px;
  }

  .resa-car .column.img-wp {
    flex: none;
    position: relative;
    width: 280px;
    border-right: 1px solid shade($gy-a,5%);
    vertical-align: middle;
  }

  .resa-car .column.img-wp .img-fluid {
    width: 325px;
  }

  .resa-car .column.img-wp .galery-more {
    background-color: tint($gy-a,25%);
    font-size: px(22);
    width: 40px;
    height: 40px;
    padding-top: 7px;
    line-height: 1em;
    font-weight: 400;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    color: $or-a;
  }

  .resa-car .column.price-wp {
    flex: none;
    border-radius: 0 10px 0 0;
    text-align: center;
    width: 400px;
    background-color: $wh-a;
    border-left: 1px solid shade($gy-a,5%);
    padding: 0;
    display: flex;
    border-radius: 0 10px 0 0;
    @include media-breakpoint-down(lg) {
      /*width: 500px;*/

      .bloc {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .resa-car .column.price-wp .price {
    font-size: px(28);
    margin-right: 10px;
    font-weight: 600;
    color: $or-a;
  }

  .resa-car .column.price-wp .day {
    display: inline-block;
    line-height: 1em;
    font-size: px(11);
    font-style: italic;
    font-weight: 400;
    color: $gy-a;
  }

  .resa-car .column.price-wp .total {
    position: relative;
    text-transform: uppercase;
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    color: $bl-a;
  }

  .resa-car .column.price-wp .total:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    margin-top: 0;
    // @extend .sprite;
    // @extend .arrow-select;
    transform: rotate(-90deg) scale(0.9);
  }

  .resa-car .column.price-wp .price-list {
    margin-bottom: 10px;

    .small {
      display: block;
      font-size: 11px;
      padding: 5px 10px;
      margin-top: 5px;
      line-height: 1em;
      background-color: shade($gy-a, 5%);
      border-radius: 20px;
      color: $bl-a;

      &.inverse {
        background-color: $bl-a;
        color: $wh-a;
      }
    }
  }

  .label-cheap {
    border-radius: 10px 0 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $bl-a;
    color: #FFF;
    font-size: 11px;
    padding: 3px 9px;
  }

  .resa-car .column.price-wp .price-total {
    font-family: 'museo500';
    font-size: px(20);
  }

  .resa-car .column.price-wp .btn-submit {
    padding: 0.6rem 0;
    text-align: center;
    width: 100%;
    color: $wh-a;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    background-color: tint($bk-a, 30%);
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
  }

  .resa-car .column.price-wp .btn-submit.orange {
    background-color: $or-a;
  }

  .resa-car .column.price-wp .btn-submit .small {
    display: block;
    line-height: 1em;
    margin-top: 3px;
    text-align: center;
    font-size: px(9);
    font-style: italic;
    text-transform: none;
  }

  .resa-car .column.price-wp .btn-submit.orange:focus,
  .resa-car .column.price-wp .btn-submit.orange:hover {
    background-color: shade($or-a, 10%) !important;
    color: $wh-a !important;
  }

  .resa-car .column.price-wp .btn-submit:focus,
  .resa-car .column.price-wp .btn-submit:hover {
    background-color: shade($bk-a, 5%) !important;
    color: $wh-a !important;
  }

  .resa-car .column.price-wp .btn-submit.green:focus,
  .resa-car .column.price-wp .btn-submit.green:hover {
    background-color: shade($bk-a, 10%) !important;
    color: $wh-a !important;
  }

  .resa-car .column.price-wp .txt-small {
    font-size: 10px;
    display: block;
    line-height: 1.2em;
    font-weight: 600;
  }

  .resa-car .column.content-wp {
    vertical-align: top;
    padding: 20px 30px;
  }

  .resa-car .column.content-wp .title {
    font-size: px(26);
    font-weight: 400;
    color: $or-a;
    margin-bottom: 5px;
  }

  .resa-car .column.content-wp .title .similar {
    color: $bk-a;
    font-size: px(12);
    font-weight: 400;
    font-style: italic;
  }

  .resa-car .column.content-wp .category {
    display: inline-block;
    line-height: 1em;
    font-size: px(11);
    font-weight: 300;
    background-color: $gy-a;
    padding: 5px 10px;
    border-radius: 15px;
  }

  .resa-car .txt-presentation {
    font-size: px(13);
    line-height: 1.4em;
    font-weight: 300;
    margin-top: 10px;
    display: block;
  }

  .resa-car .option-wp {
    margin-top: 15px;
  }

  .resa-car .option-wp .item-sub {
    float: left;
    width: 40%;
    margin-bottom: 2px;
  }

  .resa-car .option-wp .txt {
    font-size: px(12);
  }

  .resa-car .option-wp .sprite,
  .resa-car .option-wp .txt {
    vertical-align: middle;
  }

  .resa-car .option-wp .sprite {
    margin-right: 5px;
  }

  .resa-car .price-title {
    font-size: px(12);
    font-weight: 600;
    display: block;
    line-height: 1em;
    margin-bottom: 7px;
    text-transform: uppercase;
  }

  .resa-car .column.price-wp .bloc {
    padding: 30px 30px 15px;
    height: 100%;
    width: 50%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    // display: flex;
    // flex-flow: column wrap;
    // align-items: flex-start;
    // justify-content: center;
  }

  .resa-car .column.price-wp .bloc.left {
    background-color: tint($gy-a, 50%);
    border-right: 1px solid shade($gy-a,5%);
  }
  @include media-breakpoint-down(md) {
    .item {
      margin-bottom: 40px;
    }

    .resa-car .column.price-wp .price-list .small {
      display: inline-block;
    }

    .resa-car .unavailable-date,
    .resa-car .unavailable-txt {
      display: inline-block;
      margin: 0;
    }

    .resa-car.unavailable {
      padding-bottom: 93px;
    }

    .resa-car.unavailable:after {
      width: calc(100% + 0px);
      height: calc(100% - 93px);
    }

    .resa-car.unavailable .column.price-wp .bloc.left {
      justify-content: center;
    }

    .resa-car {
      padding-bottom: 163px;
    }

    .resa-car .column.img-wp {
      width: 270px;
    }

    .resa-car .column.img-wp .img-fluid {
      width: 270px;
    }

    .resa-car .column.price-wp {
      background-color: tint($gy-a, 50%);
      height: auto !important;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
      width: 100%;
      flex-flow: row nowrap;
      border-top: 1px solid shade($gy-a,5%);
    }

    .resa-car .column.price-wp .bloc {
      width: 50%;
      height: 162px;
    }

    .resa-car .column.price-wp .bloc.left {
      order: 1;
      border-left: 1px solid shade($gy-a,5%);
    }

    .resa-car.except-parc {
      padding-bottom: 131px;
    }

    .resa-car.except-parc .column.price-wp .bloc {
      width: 100%;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    .resa-car.except-parc .column.price-wp .total br {
      display: none;
    }

    .resa-car.except-parc .column.price-wp .btn-submit {
      width: 275px;
    }

    .resa-car.except-parc .column.price-wp .total:before {
      top: 10px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .resa-car.unavailable {
      padding-bottom: 0;
    }

    .resa-car.unavailable:after {
      height: calc(100% - 90px) !important;
    }

    .resa-car .unavailable-date,
    .resa-car .unavailable-txt {
      text-align: left;
    }

    .promo {
      top: 5px;
      right: 5px;
      transform: rotate(0) scale(.75);
    }

    .resa-car.unavailable:after {
      width: calc(100% + 0px);
      height: calc(100% - 74px);
    }

    .resa-car-wp .resa-car .column.content-wp {
      padding-top: 10px;
    }

    .resa-car .column.img-wp .img-fluid {
      width: 70%;
      margin: 20px auto;
      display: block;
    }

    .item {
      margin-bottom: 20px;
    }

    .resa-car {
      display: block;
      padding-bottom: 0;
    }

    .resa-car .column.img-wp {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .resa-car .column {
      display: block;
      width: 100% !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      border-right: none !important;
    }

    .resa-car .column.content-wp {
      border-top: 1px solid shade($gy-a, 10%);
      border-bottom: 1px solid shade($gy-a, 10%);
    }

    .resa-car .column.price-wp {
      position: relative;
      bottom: auto;
      left: auto;
      padding: 0 !important;
      border-top: none !important;
    }

    .resa-car .column.price-wp .btn-submit,
    .resa-car .column.price-wp .price-list {
      display: block;
      float: none;
      width: 100%;
      margin-top: 0;
      border: none !important;
    }

    .resa-car .column.price-wp .price-list {
      padding: 0;
      margin-top: 5px;
    }

    .resa-car .column.price-wp .btn-submit {
      margin-bottom: 0;
    }

    .resa-car .column.price-wp .bloc {
      padding: 20px;
    }

    .resa-car.except-parc {
      padding-bottom: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    .resa-car.unavailable:after {
      width: calc(100% + 0px);
      height: calc(100% - 91px);
    }

    .resa-car .unavailable-date,
    .resa-car .unavailable-txt {
      text-align: center;
    }

    .resa-car .unavailable-date {
      margin-top: px(10);
    }

    .resa-car .column.content-wp .title {
      font-size: px(22);
    }

    .resa-car .column.img-wp {
      width: 100%;
    }

    .resa-car .column.img-wp .img-fluid {
      width: 100%;
      margin: 10px auto;
    }

    .resa-car .column.price-wp {
      width: 100%;
      flex-flow: column wrap;
    }

    .resa-car .column.price-wp .bloc {
      width: 100%;
      height: auto;
    }

    .resa-car .column.price-wp .bloc.left {
      order: 0;
      border-bottom: 1px solid shade($gy-a,5%);
      border-left: none;
    }

    .resa-car .column.price-wp .bloc {
      padding: 7px 20px 15px;
    }

    .resa-car.except-parc .column.price-wp .bloc {
      width: 100%;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: center;
    }

    .resa-car.except-parc .column.price-wp .btn-submit {
      width: 100%;
    }
  }
}

.resa-step-2 {
  .item {
    border: 1px solid $gy-a;
    box-shadow: 0 1px 3px rgba(0,0,0,.05);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 75px;
    font-size: px(13);
    margin-bottom: 10px;
    display: table;
    background-color: $wh-a;

    &.price-off {
      @include media-breakpoint-down(xs) {
        padding-bottom: 8px !important;

        &:after {
          display: none !important;
        }
      }
    }

    &.total {
      margin-top: px(30);
      background-color: $bl-a;
      border-bottom: 1px solid $gy-a;
      padding: px(8);

      &:after {
        display: none !important;
      }

      .title {
        color: $wh-a;
        text-transform: uppercase;
        font-weight: 500;
        padding-left: px(10);
      }

      .col-option-2 {
        border-radius: 5px;
        border: none;
        font-size: px(20);
        color: $bl-a;
        background-color: $wh-a;
        font-family: 'museo500';

        &:before {
          display: none;
        }
      }
      @include media-breakpoint-down(xs) {
        margin-top: px(20);

        .col-option-1 {
          padding-left: 0 !important;

          .title {
            font-size: px(15);
          }
        }

        .col-option-2 {
          width: 110px !important;
          height: 57px !important;
          padding-top: px(0);

          .price {
            font-size: 18px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .more-info-trigger {
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 2;
      margin-top: -9px;
      color: shade($gy-a, 30%);
      outline: none;

      .material-icons {
        font-size: 21px;
      }
    }

    .col-option-1,
    .col-option-2 {
      display: table-cell;
      padding: 0 15px;
      vertical-align: middle;
    }

    .col-option-1 {
      padding: 8px 35px 8px 8px;
      font-family: 'museo500';
    }

    .checkboxradio-wp {
      display: table;
      width: 100%;

      > input[type=checkbox] + label {
        font-size: px(13.5);
        height: 56px;
        width: 100%;
        display: table-cell;
        font-weight: 500;
        color: $bk-a;
        line-height: 1.15em;
        margin-bottom: 0;
        background-image: url("../images/icons/sprite-checkbox_radio-step2_3.png");
        vertical-align: middle;
        padding: 0 0 0 70px;
        background-size: 56px;

        em {
          display: block;
          font-weight: 400;
          font-size: 85%;
          color: shade($gy-a, 50%) !important;
          margin-top: px(3);
        }
        @include media-breakpoint-down(lg) {
          font-size: px(13);
        }
        @include media-breakpoint-down(sm) {
          font-size: px(15);
        }
        @include media-breakpoint-down(xs) {
          font-size: px(14);
        }
      }

      > input[type=checkbox] + label {
        background-position: 0 bottom;
      }

      > input[type=checkbox]:checked + label {
        background-position: 0 top;
        font-weight: 600;
        color: $or-a;
      }
    }

    .quantite, .choix {
      padding: 0 0 0 70px;

      .selectpicker-wp {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        color: shade($gy-a, 50%);

        .bootstrap-select .btn.dropdown-toggle {
          line-height: 1.2em;
          display: block !important;
          padding: 10px 30px 10px 17px !important;
          border: none;
          border-radius: 6px;
          background-color: $gy-a !important;
          vertical-align: center;

          .filter-option-inner-inner {
            font-size: px(16);
            font-weight: 500;
            text-align: center;
            overflow: visible;
          }
        }

      }
      .label {
        color: shade($gy-a, 50%);
        font-weight: normal;
        padding-right: 10px;
      }
      &.disabled .selectpicker-wp .bootstrap-select .btn.dropdown-toggle,
      &.disabled .label {
        opacity: 0.4;
      }
    }
  }

  /*.selectpicker-wp {
      position: absolute;
      top: 12px;
      left: 12px;
      display: block;
      height: 50px;
      width: 52px;
      vertical-align: middle;

      &.inactive {
          display: none;
      }

      .bootstrap-select .btn.dropdown-toggle {
          line-height: 1em;
          display: block !important;
          height: 50px;
          width: 52px;
          border-radius: 6px;
          padding: 18px 20px 10px 7px !important;
          border: none;
          background-color: $gy-a !important;
          // &:after {
          //   margin-top: px(-2);
          // }
          .filter-option-inner-inner {
              font-size: px(16);
              font-weight: 500;
              text-align: center;
              overflow: visible;
          }
      }
  }*/
}

.car-outclass {
  position: relative;
  border: 1px solid $gy-a;
  box-shadow: 0 1px 3px rgba(0,0,0,.05);
  border-radius: 8px;

  .header {
    background-color: tint($gy-a, 25%);
    border-bottom: 1px solid shade($gy-a, 5%);
    padding: 20px;
    border-radius: 8px 8px 0 0;
    @include media-breakpoint-down(xs) {
      padding: 15px;

      .title {
        font-size: px(18);
      }
    }
  }

  .content-wp {
    .col-left {
      width: 250px;
      float: left;
    }

    .col-right {
      width: calc(100% - 250px);
      padding-top: 10px;
      float: left;
      padding-left: 35px;
    }

    .category {
      display: inline-block;
      line-height: 1em;
      font-size: px(11);
      font-weight: 300;
      background-color: $gy-a;
      padding: 5px 10px;
      border-radius: 15px;
    }

    .similar {
      display: inline-block;
      font-style: italic;
      font-weight: 400;
      margin-left: 5px;
      font-size: px(12);
    }
    @include media-breakpoint-down(sm) {
      .col-left {
        width: 200px;
      }

      .col-right {
        width: calc(100% - 200px);
      }
    }
    @include media-breakpoint-down(xs) {
      padding: 0 20px 20px;

      .col-left {
        width: 100%;
      }

      .col-right {
        padding: 0;
        width: calc(100% - 0px);
      }
    }
  }
}

.resa-aside {
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  .header {
    border-radius: 0 15px 0 0;
    background-color: $bl-a;
    position: relative;
    color: $wh-a;
    padding: px(13) px(80) px(13) px(15);

    .category {
      text-transform: uppercase;
      font-size: px(10);
      letter-spacing: 0.1em;
      font-weight: 300;
    }

    .title {
      margin-top: px(3);
      font-size: px(17);
      font-weight: 400;
      line-height: 1.1em;

      em {
        font-size: 80%;
        font-weight: 300;
      }
    }

    .btn-edition {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: px(65);
      background-color: $gy-a;
      color: $bl-a;
      padding-top: px(7);
      font-size: 11px;
      font-family: 'museo500';
    }
  }

  .car-wp {
    padding: 20px;

    .icons-wp {
      margin-top: 10px;
      padding: 0 px(20);

      .txt {
        font-size: px(10);
        display: block;
        line-height: 1.1em;
      }

      .car-icon {
        position: relative;
        padding: px(5) 0 px(5) px(17);

        .sprite {
          position: absolute;
          top: px(0);
          left: px(-7.5);
          transform: scale(0.75);
        }
      }
    }
  }

  .options-wp {
    padding: px(10) px(20) 0;
    background-color: $gy-a;
    .btn-popup {
      position: absolute;
      top: 0;
      right: 0;
      text-transform: none;
      font-size: 10px;
      text-decoration: underline;
    }

    .item {
      position: relative;
      padding: px(9) px(80) px(9) 0;
      font-size: px(13);
      font-weight: 500;
      color: $bl-a;
      line-height: 1.2em;


      &.total {
        background-color: $bl-a;
        color: $wh-a;
        width: calc(100% + 40px);
        margin-left: -20px;
        padding: 15px 20px;

        @include media-breakpoint-down(md) {
          width: calc(100% + 50px);
          margin-left: -25px;
        }

        .price {
          top: auto;
          right: 20px;
        }
      }

      &.except {
        padding-right: 0;
      }

      .btn-detail {
        color: $bl-a;
        text-decoration: underline;
      }

      .price {
        position: absolute;
        top: px(9);
        right: 0;
        font-weight: 600;
      }

      &.promo {
        position: relative;
        padding: 5px;
        background-color: rgba(0,0,0,0.05);
        border-radius: 5px;
        border: 1px solid shade($gy-a, 10%);
        margin-bottom: px(10);
        line-height: 1.2em;
        .txt {
          font-weight: 400;
        }

        .btn-promo {
          text-align: left;
          position: relative;
          display: block;
          width: 100%;
          font-style: italic;
          font-size: px(12);
          color: $bl-a;
          font-weight: 600;
          padding-left: px(20);

          .material-icons {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -8px;
            font-size: px(15);
          }
        }
      }

      &.except {
        font-weight: 600;
        text-transform: uppercase;
        >.txt {
          font-family: 'museo500';
        }

        .price {
          font-weight: 600;
        }
      }
    }

    .item:not(:first-child) {
      border-top: 1px solid shade($gy-a, 10%);
    }

    &.step-3 {
      .price {
        font-weight: 400 !important;
        text-decoration: underline;
        text-transform: none;
        font-size: px(11);
      }

      .item:not(.except) {
        padding-right: 0;

        em {
          font-size: 95%;
          font-style: italic;
        }
      }
    }

    .txt-info {
      padding: px(0) 0 0;
      font-style: italic;
      line-height: 1.2em;
      color: $bl-a;
      font-size: px(11);
      margin-top: px(-5);
      margin-bottom: px(8);
    }
  }

  .btn-wp {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: px(16);
    padding: px(13);
    font-weight: 600;
    background-color: $or-a;
    color: $wh-a;
    text-align: center;
    letter-spacing: 0.05em;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  @include media-breakpoint-down(md) {
    .header {
      padding-top: px(9);
      padding-bottom: px(9);

      .title {
        margin-top: 0;
      }
    }

    .content-wp {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border: 1px solid $gy-a;
      border-radius: 0 0 8px 8px;

      .car-wp {
        flex: 0 0 45%;
        max-width: 45%;
        padding: 0 px(15) px(10);
      }

      .options-wp {
        flex: 0 0 55%;
        max-width: 55%;
        position: relative;
        z-index: 2;
        padding: px(10) px(25) 0;

      }

      &:after {
        content: "";
        width: 55%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $gy-a;
      }
    }

    .btn-wp {
      display: block;
      width: 240px;
      margin: px(15) auto 0;
      border-radius: 8px;
      padding-top: px(8);
      padding-bottom: px(8);
      font-size: px(14);
    }
  }
  @include media-breakpoint-down(xs) {
    .content-wp {
      display: block;

      .car-wp,
      .options-wp {
        display: block;
        width: 100%;
        max-width: 100%;
      }

      &:after {
        display: none;
      }
    }
  }
}

.resa-included {
  border-bottom: 1px solid $gy-a;
  background-color: $gy-a;
  border-radius: 8px;

  .item {
    line-height: 1.2em;
    position: relative;
    padding: px(5) 0 0 px(25) !important;
    margin-bottom: px(5);
    font-weight: 500;
    color: $bl-a;

    .txt {
      font-size: 11px;
      text-transform: none;
    }
  }

  .item .material-icons {
    content: "";
    position: absolute;
    color: $bl-a;
    top: px(3);
    left: 0;
    font-size: px(18);
  }
}

.resa-step-4 {
  position: relative;
  height: 100%;
  padding: 25px 30px;
  background-color: $wh-a;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 8px;

  .title-sub {
    color: $or-a;
    text-transform: uppercase;
    font-size: px(16);
    font-weight: 600;
    border-bottom: 1px solid $or-a;
    padding-bottom: 5px;
  }

  .list {
    margin-top: 20px;
  }

  .list li {
    display: block;
    position: relative;
    padding-left: 18px;
    font-size: px(14);
    line-height: 1.4em;
    word-break: break-word;
  }

  .list li:not(:last-child) {
    margin-bottom: 7px;
  }

  .list li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 8px;
    height: 2px;
    background-color: $or-a;
  }
}