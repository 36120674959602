// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	max-width: 1600px;
	margin: 0 auto;
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	background-color: $wh-a;
	transition: all 0.2s ease-in-out;
}
// Navigation :: Main

#header {
	padding: 10px 0;
	border-bottom: 1px solid $gy-a;

	.logo {
		position: absolute;
		top: 2px;
		left: 15px;
		color: $bl-a;
		display: flex;
		align-items: center;

		.txt {
			position: relative;
			font-size: 13px;
			font-weight: 400;
			padding-left: 10px;
		}
	}

	.list {
		position: absolute;
		top: 17px;
		right: 15px;
		display: flex;
		align-items: center;

		> * {
			position: relative;
			padding: 0 10px;

			&:first-child {
				padding-right: 15px;
			}

			&:last-child {
				padding-left: 10px;
			}

			&:not(:last-child) {
				&:before {
					content: "";
					height: 15px;
					width: 1px;
					background-color: #00285F;
					position: absolute;
					top: 50%;
					margin-top: -7.5px;
					right: 0;
				}
			}

			.phone {
				font-family: 'museo500';
				color: $bl-a;
				text-decoration: underline;
			}

			.location {
				display: flex;
				align-items: center;
				color: $or-b;
				font-weight: 500;
				font-size: 13.5px;
			}
		}
	}
	@media (max-width: 1350px) {
		.logo {
			top: -2px;
			display: block;

			.img-fluid {
				width: 175px;
			}

			.txt {
				left: -10px;
				font-size: 10.5px;
				letter-spacing: -0.01em;
			}
		}

		.list {
			.phone {
				font-size: 13px;
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.logo {
			top: 3px;
			display: block;

			.img-fluid {
				width: 150px;
			}

			.txt {
				left: -10px;
				font-size: 9px;
				letter-spacing: -0.01em;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding: 10px 0 0;

		.list {
			top: 2px;
		}
	}
	@include media-breakpoint-down(sm) {
		.list {
			top: 10px;
		}

		.logo {
			.txt {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		min-height: 90px;

		.list {
			background-color: $gy-a;
			width: 100%;
			padding: 2.5px 10px;
			right: auto;
			top: 0;
			justify-content: flex-end;

			> * {
				position: relative;
				padding: 0 5px;
			}
		}

		.logo {
			padding-top: 38px;

			.img-fluid {
				width: 140px;
			}
		}
	}

	#nav-mobile-trigger {
		position: absolute;
		top: 54px;
		right: 15px;
	}
}

#nav-main {
	padding-left: 470px;
	padding-right: 325px;
	display: flex;

	.item {
		flex-grow: 1;
	}

	.link {
		text-align: center;
		font-size: 14px;
		padding: 15px 5px;
		display: block;
		color: $bl-a;
	}

	.link.active,
	.link:focus,
	.link:hover {
		color: $or-a;
	}
	@media (max-width: 1350px) {
		padding-left: 200px;
		padding-right: 300px;
	}
	@include media-breakpoint-down(lg) {
		padding-left: 175px;
		padding-right: 300px;
	}
	@include media-breakpoint-down(md) {
		padding-right: 10px;
		padding-top: 22px;

		.link {
			padding: 10px 5px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 0;
		margin-top: 40px;
		background-color: $gy-a;
	}
	@include media-breakpoint-down(xs) {
		margin-top: 75px;
		padding: 7.5px 0;
		background-color: $gy-a;
		display: none;

		.link {
			font-size: 14px;
			padding: 5px 0;
		}
	}
}

#resa-header {
	position: relative;
	z-index: 10;
	width: 100%;
	background-color: $wh-a;
	overflow: hidden;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	transition: all 0.2s ease-in-out;
	padding: px(10) 0;

	.logo {
		position: relative;
		display: block;
		padding: 0;
	}

	.logo .img-fluid {
		display: block;
		margin: 0 auto;
	}

	.logo:after {
		content: "";
		position: absolute;
		top: -10px;
		right: -30px;
		height: calc(100% + 20px);
		width: 1px;
		background-color: tint($bk-a, 75%);
	}

	.item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		color: tint($bk-a, 50%);
	}

	.item .txt {
		font-size: px(15);
		font-weight: 400;
	}

	.item .number {
		font-size: px(18);
		line-height: 1em;
		position: relative;
		top: -1px;
		font-weight: 400;
		margin-right: 5px;
	}

	.item .number:after {
		content: ".";
	}

	.item:not(.first):before {
		content: "";
		position: absolute;
		top: 50%;
		left: -15px;
		height: 14px;
		margin-top: -7px;
		width: 1px;
		background-color: tint($bk-a, 75%);
	}

	.item.active {
		color: $or-a;
	}

	.item.active .number:after {
		display: none;
	}

	.item.active .number {
		background-color: $or-a;
		margin-right: 10px;
		font-size: px(14);
		padding-top: 5px;
		color: $wh-a;
		text-align: center;
		border-radius: 25px;
		width: 25px;
		height: 25px;
	}

	.item.active .txt {
		font-family: 'museo500';
	}
	@include media-breakpoint-down(md) {
		padding: px(5) 0;

		.logo {
			img {
				margin: 0 !important;
			}
		}

		.logo:after {
			right: -15px;
		}

		.item .txt {
			font-size: px(15);
		}

		.item .number {
			font-size: px(16);
		}

		.item.active .number {
			font-size: px(13);
			width: 20px;
			height: 20px;
			padding-top: 3px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 5px;
		.logo:after {
			right: 5px;
		}

		.row {
			align-items: flex-start !important;
		}

		.logo {
			padding-top: 1px;
		}

		.logo .img-fluid {
			display: block;
			width: 100%;
			height: auto;
			margin: 0 auto;
		}

		.logo:after {
			right: -8px;
			height: calc(100% + 40px);
		}

		.item {
			flex-flow: column wrap;
		}

		.item .number {
			margin-right: 0 !important;
		}

		.item .number:after {
			display: none;
		}

		.item .txt {
			font-size: px(13);
		}

		.item.active .number {
			font-size: px(11.5);
			width: 17px;
			height: 17px;
			padding-top: 2px;
		}

		.item.active .txt {
			margin-top: 3px;
		}

		.item:not(.active) .txt {
			padding-top: 3px;
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(2) 0;

		// .col-4 {
		// 	flex: 0 0 85px;
		// 	max-width: 85px;
		// }
		//
		// .col-8 {
		// 	flex: 0 0 calc(100% - 85px);
		// 	max-width: calc(100% - 85px);
		// }

		.row {
			align-items: center !important;
		}

		.item {
			padding-top: 0;
		}

		.item .txt {
			display: none;
		}

		.item.active .number {
			width: 20px;
			height: 20px;
			padding-top: 4px;
			font-size: px(12);
		}
	}
}

#resa-summary-wp {
	overflow: hidden;
	position: relative;
	padding: 30px 0;
	background-image: url("../images/illustrations/bg-resa.jpg");
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	@include media-breakpoint-down(md) {
		padding: 30px 0;
		background-image: url("../images/illustrations/bg-resa.jpg");
	}
	@include media-breakpoint-down(xs) {
		padding: 15px 0 15px 0 !important;
	}

	&.except-utilitaire.open {
		padding-bottom: 60px;
	}
}

#resa-summary {
	position: relative;
	display: table;
	width: 100%;
	margin: 0 auto;
	background-color: $wh-a;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);

	.column {
		position: relative;
		display: table-cell;
		vertical-align: middle;
		width: 41%;
		padding: 20px 15px 20px 0;

		&:not(.except):after {
			content: "";
			display: block;
			position: absolute;
			height: 100%;
			width: 1px;
			background-color: tint($bk-a, 75%);
			right: px(20);
			top: 0;
		}
	}

	.column.except {
		width: 18%;
	}

	.column.except .list {
		border-right: none;
	}

	.column:first-child .list {
		padding-left: 50px;
	}

	.column:first-child .list:after {
		left: 20px;
	}

	.list {
		padding: 0 15px 0 40px;
		position: relative;
		height: 100%;
	}

	.column .material-icons {
		position: absolute;
		top: 50%;
		left: 15px;
		margin-top: -10px;
		color: $bk-a;
	}

	.column.except-bis .material-icons {
		left: 5px;
		color: $bk-a;
	}

	.column.except .list:after {
		display: none;
	}

	.column.except .list {
		padding-left: 40px;
	}

	.column.except .img {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		left: 5px;
		font-size: px(18);
	}

	.place {
		text-transform: uppercase;
		display: block;
		line-height: 1.2em;
		font-weight: 600;
		color: $or-a;
		font-size: px(14);
	}

	.date {
		color: $bk-a;
		font-weight: 400;
		font-size: px(12);
		display: block;
		line-height: 1.2em;
		margin-top: 5px;
	}

	.btn-submit {
		width: 130px;
		height: 84px;
		background-color: $or-a;
		color: $wh-a;

		&.open {
			.material-icons {
				transform: rotate(180deg);
			}
		}
	}

	.btn-submit > * {
		display: inline-block;
		vertical-align: middle;
	}
	@include media-breakpoint-down(lg) {
		.column:not(.except):after {
			right: 15px;
		}
	}
	@include media-breakpoint-down(md) {
		.column {
			padding: 15px 15px 15px 0;
		}

		.column:not(.except):after {
			right: 5px;
		}

		.btn-submit {
			height: 81px;
			width: 120px;
		}
		.place {
			font-size: px(12);
		}

		.date {
			font-size: px(11);
			margin-top: px(2);
		}
	}
	@include media-breakpoint-down(sm) {
		.place {
			font-size: px(12);
		}

		.date {
			font-size: px(11);
			margin-top: px(2);
		}

		.column {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		.column.except .img {
			display: none !important;
		}

		.column .list {
			padding-left: 10px !important;
		}

		.column .material-icons {
			display: none !important;
		}

		.btn-submit {
			height: 70px;
			font-size: px(13);
			width: 90px;

			.material-icons {
				font-size: px(18);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.column.except .img {
			left: 8px !important;
			font-size: px(15) !important;
			display: block !important;
		}

		.column:after {
			display: none !important;
		}

		.column .material-icons {
			left: 7px !important;
			font-size: px(20) !important;
			display: block !important;
		}

		.column,
		.column .list {
			display: block;
			width: 100% !important;
		}

		.column .list {
			border-right: none !important;
			padding-left: 32px !important;
		}

		.column {
			border-bottom: 1px solid tint($bk-a, 75%);
			padding-top: 8px;
			padding-bottom: 8px;
		}

		.column.except {
			border-bottom: none !important;
		}

		.list:after {
			left: 10px !important;
		}

		.column.except .list:after {
			left: 10px !important;
		}

		.btn-submit {
			height: 35px;
			width: 100%;
		}
	}
}
