// Reset :: Button & Links

button {
	background-color: transparent;
	border: none;
	padding: 0;
	outline: none !important;
	cursor: pointer;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
}
// Hover :: Link

.td-und-hover-a:focus,
.td-und-hover-a:hover {
	text-decoration: underline;
}

.td-und-hover-b:focus > *,
.td-und-hover-b:hover > * {
	text-decoration: underline;
}
// Buttons :: List

.btn-a {
	display: inline-block;
	transition: all 0.2s ease-in-out;
	font-size: 14px;
	border-radius: 3px;
	line-height: 1em;
	font-weight: 500;
	text-align: center;
	padding: 12.5px 25px;
	border-width: 2px;
	border-style: solid;

	&.small {
		padding: px(10) px(17);
		font-size: px(12);
	}


	&.white-a {
		border-color: $wh-a;
		color: $wh-a;
		background-color: transparent;
		&:focus,
		&:hover {
			background-color: rgba(255,255,255,.2);
		}
	}

		&.blue-a {
			border-color: $bl-a;
			background-color: $bl-a;
			color: $wh-a !important;

			&:focus,
			&:hover {
				color: $bl-a !important;
				background-color: $wh-a;
			}
		}

		&.orange-a {
			border-color: $or-a;
			background-color: $or-a;
			color: $wh-a !important;

			&:focus,
			&:hover {
				color: $or-a !important;
				background-color: $wh-a;
			}
		}
}
// Button :: Hamburger

.btn-mobile-hamburger {
	display: block;
	margin: 0 auto;
	width: 30px;
	height: 20px;
	position: relative;
	z-index: 2;
	transform: rotate(0deg);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: $bl-a;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: all 0.2s ease-in-out;
	}

	span:nth-child(1) {
		top: 0;
	}

	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}

	span:nth-child(4) {
		top: 18px;
	}
}
// Button :: Hamburger Open

.menu-open .btn-mobile-hamburger {
	span:nth-child(1) {
		top: 18px;
		width: 0;
		left: 50%;
	}

	span:nth-child(2) {
		transform: rotate(45deg);
	}

	span:nth-child(3) {
		transform: rotate(-45deg);
	}

	span:nth-child(4) {
		top: 18px;
		width: 0;
		left: 50%;
	}
}
// Button :: Top

#btn-back-top {
	@include position(fixed, null 30px 30px null);
	z-index: 99;
	transition: all 0.2s ease-in-out;

	&:focus,
	&:hover {}
	@include media-breakpoint-down(sm) {
		right: 15px;
		bottom: 15px;
	}
}
